/// Globals
import './bootstrap';

/// Packages
// import 'bootstrap/dist/js/bootstrap.esm.min';
import '@fortawesome/fontawesome-free';
// import 'flatpickr';
// import 'chart.js';

// import feather from 'feather-icons';
// window.feather = feather;
//
// import Trix from 'trix';
// window.trix = Trix;
//
// import sweetAlert from 'sweetalert';
// window.sweetAlert = sweetAlert;
//
// import moment from 'moment/moment';
// window.moment = moment;
//
// /// Helpers
// import AlertHelper from './shared/helpers/AlertHelper';
// window.AlertHelper = new AlertHelper;
//
// import DateHelper from './shared/helpers/DateHelper';
// window.DateHelper = new DateHelper;

import 'isotope-layout';
import 'waypoints/lib/noframework.waypoints';

import PureCounter from '@srexi/purecounterjs/js/purecounter';
window.PureCounter = PureCounter;

import GLightbox from 'glightbox';
window.GLightbox = GLightbox;

import AOS from 'aos';
window.AOS = AOS;

import Typed from 'typed.js';
window.Typed = Typed;

import Swiper from 'swiper';
window.Swiper = Swiper;