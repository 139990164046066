/// CSS
import '../sass/guest.scss';

/// Vendor
import './vendor';

/// Custom
// import './guest/custom';

/// Vue Components
import { createApp } from 'vue/dist/vue.esm-bundler';
//import ExampleComponent from './guest/components/ExampleComponent.vue';

const app = createApp({});
//app.component('example-component', ExampleComponent);
app.mount('#app');